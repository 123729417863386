<template>
    <section class="h-100 container-form d-flex flex-lg-row flex-column align-items-start">
        <div class="text-form mt-5 w-50 align-self-lg-center align-self-start">
            <h1 class="mb-5" :style="styleH1">Categorias</h1>
            <p v-html="text" class="paragraph-form"></p>
        </div>

        <div class="divider divider-select mx-12" />

        <div class="align-self-center d-flex flex-column align-items-center justify-content-between w-100 mt-5">
            <!--<CategoriesGroupSelect :pSelecteds="[]"
                             :categories="categories"
                             v-model="modelSelecteds"></CategoriesGroupSelect>-->
            <CategoriesGroupAutocomplete :pSelecteds="[]"
                                         class="w-100"
                                         :categories="categories"
                                         v-model="modelSelecteds"></CategoriesGroupAutocomplete>

            <v-btn :style="styleButton"
                   elevation="0"
                   class="btn w-100 mt-5"
                   @click="handleConfirmCategories">Confirmar</v-btn>
        </div>
    </section>
</template>

<script>
    import axios from 'axios';
    //import CategoriesGroupSelect from '../components/CategoriesGroupSelect.vue'
    import CategoriesGroupAutocomplete from '../components/CategoriesGroupAutocomplete.vue'

    export default {
        name: 'Selecionar',

        components: {
            //CategoriesGroupSelect,
            CategoriesGroupAutocomplete,
        },

        data() {
            return ({
                categories: [],
                modelSelecteds: [],
                text:
                    ` Selecione as categorias dos informes que deseja receber. Vamos enviá-las para seu e-mail semanalmente.`,
                styleH1: {
                    color: this.$colors.categories,
                },
                styleButton: {
                    backgroundColor: this.$colors.categories,
                    color: this.$colors.grays.white,
                },
            })
        },

        methods: {
            fetchCategories: function () {
                const user = this.$store.getters.authUser;

                axios.get('/api/Home/GetCategories?id=' + user.id)
                    .then(resp => {
                        this.categories = resp.data;
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },

            handleConfirmCategories: function () {
                const user = this.$store.getters.authUser;
                const objPut = {
                    user_id: user.id,
                    insertions: this.modelSelecteds,
                    removals: []
                }

                const token = this.$store.getters.authToken;
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
                axios.put('/api/Home/UpdateFavoriteCategory', objPut)
                    .then(() => {
                        this.$router.push('Informes')
                    })
                    .catch(err => console.log(err))
            }
        },

        created() {
            this.fetchCategories();
        }
    }
</script>

<style>
    .divider-select {
        background: linear-gradient(
            180deg,
            rgba(47, 128, 237, 0) 0%,
            #2F80ED 36.98%,
            #2F80ED 66.67%,
            rgba(47, 128, 237, 0) 100%
        );
    }

    @media (max-width: 960px) {
        .divider-select {
            background: linear-gradient(
                90deg,
                rgba(47, 128, 237, 0) 0%,
                #2F80ED 36.98%,
                #2F80ED 66.67%,
                rgba(47, 128, 237, 0) 100%
            );
        }
    }
</style>